import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import ImageMapper from 'react-image-mapper';
import Link from 'next/link';

const NewPanorama = () => {
	const [activityName, setActivityName] = useState('');
	return (
		<Wrapper
			background={'/static/images/Panoramic/compressed/background.jpg'}
			exit="exit"
		>
			<Link href="/activites/" passHref>
				<CircleLink
					onMouseEnter={() => setActivityName('Activités')}
					onMouseLeave={() => setActivityName('')}
					whileHover={{
						backgroundColor: 'rgba(255,255,255,0.3)',
						transition: { duration: 1 },
					}}
				></CircleLink>
			</Link>
			{activityName && <Tooltip>{activityName}</Tooltip>}
		</Wrapper>
	);
};
const Wrapper = styled(motion.div)`
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-image: url(${(props) => props.background});

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	// backdrop-filter: blur(6px);

	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
`;
const CircleLink = styled(motion.a)`
	width: 160px;
	height: 160px;
	border: 2px solid rgba(255, 255, 255, 0.4);
	border-radius: 50%;
	position: fixed;
	left: 57%;
	top: 60%;
	transform: translate(-67%, -50%);
`;
const Tooltip = styled.div`
	left: 50%;
	// right: 0;
	bottom: 20px;
	position: absolute;
	color: #fff;
	padding: 0.4em;
	font-size: 1em;
	background: rgba(0, 0, 0, 0.8);
	// transform: translate3d(-50%, -50%, 0);
	border-radius: 5px;
	pointer-events: none;
	z-index: 100000;
	display: flex;
	justify-content: center;
	mergin: 0 auto;
`;
export default NewPanorama;
