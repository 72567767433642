import * as constants from './constants';

export const setBgColor = (value) => async (dispatch) => {
	dispatch({ type: constants.CHANGE_BG_COLOR, payload: value });
};
export const setNavLight = (value) => async (dispatch) => {
	dispatch({ type: constants.NAV_LIGHT, payload: value });
};
export const setVisibleNav = (value) => async (dispatch) => {
	dispatch({ type: constants.VISIBLE_NAV, payload: value });
};
