import React, { useEffect, useState } from 'react';
import { withRouter } from 'next/router';
import { useAuth } from '../context/AuthContext';
import { wrapper } from '../redux/store';
import { isAuth } from '../redux/modules/auth/actions';
import {setBgColor, setNavLight} from '../redux/modules/superStyling/actions';
import { useDispatch, useSelector } from 'react-redux';
import { connect } from 'react-redux';
import NewPanorama from '../components/panorama/NewPanorama';
import PanoramaImage from '../components/panorama/PanoramaImage';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Loader from '../components/panorama/Loader';
const pageVariants = {
	pageInitial: {
		scale: 1,

		opacity: 1,
	},
	pageAnimate: {
		scale: 1,

		opacity: 1,
	},
	pageExit: {
		scale: 1.8,

		opacity: 0,
		transition: {
			delay: 0.6,
			duration: 3,
		},
	},
};
const compVariants = {
	initial: { opacity: 0 },
	enter: {
		opacity: 1,
		transition: { duration: 1, delay: 0.6 },
	},
	exit: {
		opacity: 0,
		transition: { duration: 1 },
	},
};

const Home = ({ router }) => {
	const dispatch = useDispatch();
	const { user } = useSelector((state) => state.auth);
	const { lectures } = useSelector((state) => state.activities);
	const [displayLoader, setDisplayLoader] = useState(true);
	const [panoDisplay, setPanoDisplay] = useState(false);
	// useEffect(() => {
	// 	dispatch(isAuth())
	// 		.then((data) => {
	// 			// router.push(`/user/${data.uid}`);
	// 		})
	// 		.catch((err) => {
	// 			// router.push('/login');
	// 		});
	// }, []);

	useEffect(() => {
		dispatch(setNavLight(false));
		dispatch(setBgColor('#000'));
		dispatch(isAuth())
			.then((data) => {
				if (!data) {
					router.push('/login');
				}
			})
			.catch((err) => {
				router.push('/login');
			});
		// if (Object.keys(user).length <= 0) router.push('/login');

		//
	}, []);

	return (
		<Container
			variants={pageVariants}
			initial="pageInitial"
			animate="pageAnimate"
			exit="pageExit"
		>
			{/* <Img background={`/static/images/background-educ.png`} /> */}

			<Loader
				displayLoader={displayLoader}
				onEnter={() => {
					setDisplayLoader(false);
					setPanoDisplay(true);
				}}
			/>

			<AnimatePresence>
				{panoDisplay && (
					<PanoWrapper
						variants={compVariants}
						initial="initial"
						animate="enter"
						exit="exit"
					>
						<NewPanorama />
					</PanoWrapper>
				)}
			</AnimatePresence>
		</Container>
	);
};
const Container = styled(motion.div)`
	position: absolute;
	top: 0;
	bottom: 0;
	left: 0;
	right: 0;
	height: 100vh;
`;
const PanoWrapper = styled(motion.div)`
	position: fixed;
	padding: 0;
	margin: 0;

	bottom: 0;
	left: 0;

	width: 100%;
	height: 100vh;
	overflow: hidden;
	// z-index: 98;
	background: black;
`;

export default connect((state) => state)(withRouter(Home));
