import React, { useEffect, useRef, useState } from 'react';
import styled from 'styled-components';
import ImageMapper from 'react-image-mapper';
import { motion } from 'framer-motion';
import Router, { useRouter } from 'next/router';
import useWindowSize from '../../components/hooks/useWindowSize';
const PanoramaImage = () => {
	const router = useRouter();
	const [isPanning, setPanning] = useState(false);
	const [load, setLoad] = useState(true);
	const [hoveredArea, setHoveredArea] = useState(null);
	const [zoomFunction, setZoomFunction] = useState(false);
	const [position, setPosition] = useState({
		oldX: 0,
		oldY: 0,
		x: 0,
		y: 0,
		z: 1.6,
	});
	const [mousePosition, setMousePosition] = useState({
		x: 0,
		y: 0,
	});
	const [width, setWidth] = useState(0);
	const [height, setHeight] = useState(0);

	const { innerHeight, innerWidth } = useWindowSize();
	useEffect(() => {
		setWidth(innerWidth);
		setHeight(innerHeight);
		console.log(innerWidth);
		if (innerWidth < 1280 && innerWidth > 800) {
			setPosition({ ...position, z: 3 });
		} else if (innerWidth < 800) {
			setPosition({ ...position, z: 4 });
		}
	}, [innerHeight, innerWidth]);
	const containerRef = useRef();

	const onLoad = (e) => {
		setLoad(false);
	};

	const onMouseDown = (e) => {
		e.preventDefault();
		setPanning(true);
		setPosition({
			...position,
			oldX: e.clientX,
			oldY: e.clientY,
		});
	};
	const onTouchStart = (e) => {
		setPanning(true);
		setPosition({
			...position,
			oldX: e.touches[0].clientX,
			oldY: e.touches[0].clientY,
		});
	};
	useEffect(() => {
		const touchend = () => {
			setPanning(false);
		};

		const touchmove = (event) => {
			event.preventDefault();
			const xCalc = Math.min(
				Math.max(parseInt(position.x), (width / 2) * -1),
				width / 2
			);
			// const yCalc = position.y + event.clientY - position.oldY;
			const yCalc = Math.min(
				Math.max(parseInt(position.y), (height / 2) * -1 + 50),
				height / 2 - 50
			);

			setMousePosition({
				x: event.clientX,
				y: event.clientY,
			});

			if (isPanning) {
				setPosition({
					...position,
					x: xCalc + event.touches[0].clientX - position.oldX,
					y: yCalc + event.touches[0].clientY - position.oldY,
					oldX: event.touches[0].clientX,
					oldY: event.touches[0].clientY,
				});
			}
		};

		window.addEventListener('touchend', touchend, { passive: false });
		window.addEventListener('touchmove', touchmove, { passive: false });

		return () => {
			window.removeEventListener('touchend', touchend, { passive: false });
			window.removeEventListener('touchmove', touchmove), { passive: false };
		};
	});

	useEffect(() => {
		const mouseup = () => {
			setPanning(false);
		};

		const mousemove = (event) => {
			const xCalc = Math.min(
				Math.max(parseInt(position.x), (width / 2) * -1),
				width / 2
			);
			// const yCalc = position.y + event.clientY - position.oldY;
			const yCalc = Math.min(
				Math.max(parseInt(position.y), (height / 2) * -1 + 50),
				height / 2 - 50
			);

			setMousePosition({
				x: event.clientX,
				y: event.clientY,
			});

			if (isPanning) {
				setPosition({
					...position,
					x: xCalc + event.clientX - position.oldX,
					y: yCalc + event.clientY - position.oldY,
					oldX: event.clientX,
					oldY: event.clientY,
				});
			}
		};

		window.addEventListener('mouseup', mouseup);
		window.addEventListener('mousemove', mousemove);

		return () => {
			window.removeEventListener('mouseup', mouseup);
			window.removeEventListener('mousemove', mousemove);
		};
	});

	const MAP = {
		name: 'my-map',
		areas: [
			{
				name: '',
				shape: 'circle',
				coords: [2268, 1305, 120],
				// coords: [216, 757, 100],
				link: `/activites/`,
				fillColor: 'transparent',
				lineWidth: 2,
				strokeColor: 'rgba(255,255,255,0.3)',
				// preFillColor: 'green',
			},
		],
	};

	const moveOnImage = (evt) => {
		const coords = {
			x: evt.nativeEvent.offsetX * 4.17,
			y: evt.nativeEvent.offsetY * 4.17,
		};
		console.log(`You moved on the image at coords ${JSON.stringify(coords)} !`);
	};
	const clicked = (area, evt) => {
		// console.log(area);
		// setZoomFunction(true);
		// setPosition({
		// 	...position,
		// 	z: 4,
		// });

		router.push(area.link);
	};
	const enterArea = (area) => {
		setHoveredArea(area);
	};
	const leaveArea = (area) => {
		setHoveredArea(null);
	};
	const getTipPosition = (area) => {
		return {
			top: `${area.center[1]}px`,
			left: `${area.center[0]}px`,
		};
	};

	const scrollBar = () => {
		return (
			<ScrollBar>
				<motion.div
					onMouseDown={onMouseDown}
					initial={{
						x: position.x - 25,
					}}
					animate={{
						x: position.x + 25,
					}}
					transition={{
						repeat: 2,
						repeatType: 'reverse',
						duration: load ? 1 : 0,
					}}
				>
					<div
						className="bar"
						style={{ transform: `translate(${position.x}px)` }}
					>
						&nbsp;
					</div>
				</motion.div>
			</ScrollBar>
		);
	};

	return (
		<Wrapper background={`/static/images/temp-bg.jpg`}>
			{' '}
			<motion.div
				className="PanAndZoomImage-container"
				// initial={{
				// 	x: position.x - 12,
				// }}
				// animate={{
				// 	x: position.x + 12,
				// }}
				// transition={{
				// 	repeat: 2,
				// 	repeatType: 'reverse',
				// 	duration: load ? 1 : 0,
				// }}
			>
				<div
					onMouseDown={onMouseDown}
					onTouchStart={onTouchStart}
					style={{
						transform: `translate(${position.x}px, ${position.y}px)  scale(${position.z})`,
						transition: zoomFunction ? `3s` : ``,
					}}
					// onWheel={onWheel}
				>
					<ImageMapper
						className="PanAndZoomImage-image"
						alt="floorplan"
						src={'/static/images/temp-bg.jpg'}
						onLoad={() => onLoad()}
						onClick={(area, evt) => clicked(area, evt)}
						width={width}
						imgWidth={4100}
						onMouseEnter={(area) => enterArea(area)}
						onMouseLeave={(area) => leaveArea(area)}
						// onMouseMove={(area, _, evt) => moveOnArea(area, evt)}
						// onImageClick={(evt) => clickedOutside(evt)}
						// onImageMouseMove={(evt) => moveOnImage(evt)}
						map={MAP}
						lineWidth={1}
					/>
					{/* {hoveredArea && (
						<Tooltip style={{ ...getTipPosition(hoveredArea) }}>
							{hoveredArea && hoveredArea.name}
						</Tooltip>
					)} */}
				</div>
			</motion.div>{' '}
		</Wrapper>
	);
};

const Wrapper = styled.div`
	// position: fixed;
	// padding: 0;
	// margin: 0;

	// top: 0;
	// left: 0;

	width: 100%;
	height: 100vh;
	overflow: hidden;
	z-index: 98;
	background: black;
	:after {
		content: '';
		display: block;
		height: 100%;
		// width: 6000px;
		position: absolute;
		top: 0;
		left: 0;
		bottom: 0;
		right: 0;
		// background-image: url(${(props) => props.background});
		// background-repeat: no-repeat;
		// background-position: center;
		// z-index: 100;
		// background-size: 100% 100%;
		pointer-events: none;
	}

	// overflow: hidden;

	.PanAndZoomImage-image {
		// min-width: 100%;
		// max-width: 100%;
	}
	.PanAndZoomImage-container {
		// position: relative;
		// :after {
		// 	content: '';
		// 	display: block;
		// 	height: 100%;
		// 	width: 6000px;
		// 	position: absolute;
		// 	top: 0;
		// 	left: 0;
		// 	bottom: 0;
		// 	right: 0;
		// 	background-image: url(${(props) => props.background});
		// 	background-repeat: no-repeat;
		// 	background-position: center;
		// 	z-index: 100;
		// 	background-size: 100% 100%;
		// 	pointer-events: none;
		// }
	}
`;
const Tooltip = styled.div`
	position: absolute;
	color: #fff;
	padding: 0.4em;
	font-size: 0.7em;
	background: rgba(0, 0, 0, 0.8);
	transform: translate3d(-50%, -50%, 0);
	border-radius: 5px;
	pointer-events: none;
	z-index: 1000;
`;
const ScrollBar = styled.div`
	position: fixed;
	bottom: 0;
	width: 100%;
	z-index: 1000;
	height: 12px;
	background: rgba(0, 0, 0, 0.3);

	.bar {
		width: 100%;
		height: 12px;
		margin: 0 auto;
		background: green;
		border-radius: 25px;
	}
`;
export default PanoramaImage;
