import React, { useState, useEffect, useRef } from 'react';
import styled from 'styled-components';
import { motion, AnimatePresence } from 'framer-motion';
import Image from 'next/image';
const loadVariants = {
	initial: { scale: 0, opacity: 0 },
	enter: {
		scale: 1,

		opacity: 1,
		transition: { duration: 1 },
	},
	exit: {
		opacity: 0,
		transition: { duration: 1 },
	},
};
const compVariants = {
	initial: { scale: 1, opacity: 1 },
	exit: {
		scale: 0,
		opacity: 0,
		transition: { duration: 1 },
	},
};
const Loader = ({ displayLoader, onEnter }) => {
	return (
		<AnimatePresence>
			{displayLoader && (
				<Wrapper
					background={'/static/images/pano-main-bg.jpg'}
					variants={compVariants}
					exit="exit"
				>
					<motion.div
						variants={loadVariants}
						initial="initial"
						animate="enter"
						exit="exit"
						onAnimationComplete={onEnter}
					>
						<Image
							src="/static/images/pallal-logo-sans-frame-2021.png"
							width={250}
							height={327}
						/>
					</motion.div>
				</Wrapper>
			)}
		</AnimatePresence>
	);
};
const Wrapper = styled(motion.div)`
	position: fixed;
	top: 0%;
	left: 0%;
	width: 100%;
	height: 100%;
	background-image: url(${(props) => props.background});

	background-repeat: no-repeat;
	background-position: center;
	background-size: cover;
	// backdrop-filter: blur(6px);

	z-index: 10000;
	display: flex;
	justify-content: center;
	align-items: center;
`;

export default Loader;
